<template>
  <div class="main">
    <Navbar></Navbar>
    <Impressum></Impressum>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "../sections/Navbar";
import Footer from "../components/Footer";
import Impressum from "../sections/Impressum.vue";

export default {
  name: "ImpressumPage",
  components: { Navbar, Footer, Impressum },
  data() {
    return {
      currentYear: ""
    };
  },
  methods: {
    getCurrentYear: function() {
      return new Date().getFullYear();
    }
  },
  mounted() {
    this.currentYear = this.getCurrentYear();
  }
};
</script>
