<template>
  <div>
    <Navbar></Navbar>
    <UnderConstruction></UnderConstruction>
<!--    <banner></banner>-->
<!--    <business></business>-->
<!--    <contact></contact>-->
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "../sections/Navbar.vue";
import Footer from "../components/Footer.vue";
import UnderConstruction from "../sections/UnderConstruction.vue";

export default {
  name: "BusinessPage",
  components: { Navbar, Footer, UnderConstruction },
  data() {
    return {
      isOpen: false
    };
  }
};
</script>

<style></style>
