<template>
  <div class="section">
    <div class="container">
      <span class="anchor" id="business"></span>
      <div class="level business">
        <div class="container has-text-centered business-text">
          <h1 class="title">
            {{ $t("construction.text") }}
          </h1>
          <div class="spacer"></div>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  name: "UnderConstruction"
};
</script>

<style scoped>

.spacer {
  height: 30vh;
}

.business {
  margin-top: 10vh;
}

.business-text {
  max-width: 40vw;
}


@keyframes delayVisible {
  to {
    visibility: visible;
  }
}

@media only screen and (max-width: 800px) {

  .business-text {
    max-width: 60vw;
  }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .section-animated {
    margin-bottom: 50vh;
  }
  .business-text {
    max-width: 90vw;
  }
  .business-left {
    max-width: 90vw;
    padding-bottom: 5vh;
  }
  .business-right {
    max-width: 90vw;
  }
}
</style>
