<template>
  <section class="section">
    <div class="container has-text-centered mb-2">
      <h1 class="title">
        {{ $t("imp.header") }}
      </h1>
      <h2 class="subtitle">
        {{ $t("imp.text") }}
      </h2>
    </div>
    <div class="container has-text-centered mb-2">
      <h1 class="title">
        {{ $t("imp.contactHeader") }}
      </h1>
      <h2 class="subtitle">
        {{ $t("imp.contactText") }}
      </h2>
    </div>
    <div class="container has-text-left">
      <h1 class="title">
        {{ $t("imp.legalHeader") }}
      </h1>
      <h2 class="subtitle">
        {{ $t("imp.legalSubheader1") }}
      </h2>
      <h3 class="text mb-3">
        {{ $t("imp.legalSubtext1") }}
      </h3>
      <h2 class="subtitle">
        {{ $t("imp.legalSubheader2") }}
      </h2>
      <h3 class="text  mb-3">
        {{ $t("imp.legalSubtext2") }}
      </h3>
      <h2 class="subtitle">
        {{ $t("imp.legalSubheader3") }}
      </h2>
      <h3 class="text  mb-3">
        {{ $t("imp.legalSubtext3") }}
      </h3>
      <h2 class="subtitle">
        {{ $t("imp.legalSubheader4") }}
      </h2>
      <h3 class="text  mb-3">
        {{ $t("imp.legalSubtext4") }}
      </h3>
    </div>
    <hr />
  </section>
</template>

<script>
export default {
  name: "Impressum",
};
</script>

<style>
@import "../../node_modules/timeline/dist/css/timeline.min.css";

@media screen and (max-width: 800px) {
  .section {
    margin-top: 10vh;
  }
}
</style>
